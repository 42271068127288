* {
  font-family: 'Lato';
}
.container {
  max-width: 1280px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 16px;
}
.hidden {
  overflow: hidden;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #9F9F9F;
  @media (min-width: 768px) {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &__item {
    margin-right: 7px;
    display: none;
    align-items: center;

    &:nth-child(1) {
      display: flex;
    }

    &:nth-child(2) {
      display: flex;
    }

    &:nth-child(3) {
      display: flex;
    }

    &:nth-child(4) {
      display: flex;
    }

    &:last-child {
      margin-right: 0;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      margin-left: 7px;
      color: #9F9F9F;
    }

    @media (min-width: 992px) {
      display: flex;
    }
  }

  .active {
    span {
      color: #000000;
    }
  }
}


.tags__block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 32px;
  padding-bottom: 32px;
  .tags__item {
    margin-right: 16px;
    margin-top: 16px;
    padding: 12px 16px;
    background: rgba(8, 125, 210, 0.05);
    border-radius: 31px;
    &:hover {
      cursor: pointer;
      background: #095185;
      span {
        color: #FFFFFF;
      }
    }
    span {
      color: #658CA8;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .active {
    background: #087DD2;

    span {
      color: #FFFFFF;
    }
  }
}

.select {
  position: relative;
  text-align-last: left;
  font-size: 16px;
  line-height: 22px;
  max-width: 92px;
  width: 100%;
  background: #000000;
  border-radius: 1000px;
  &__label {
    color: #4F4F4F;
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
    display: block;
  }

  &__current {
    width: 100%;
    padding: 8px 14px 8px 14px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    color: #FFFFFF;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    img {
      margin-right: 4px;
    }
    &::after {
      content: url("/img/select-arrow.svg");
      position: absolute;
      right: 14px;
    }
  }

  &__list {
    width: 100%;
    overflow: hidden;
    margin-top: 8px;
    display: none;
    position: absolute;
    top: 100%;
    z-index: 10;
    background: #FFFFFF;
    padding: 10px 8px;
    background: #d0d0d0;
    border: 1px solid #000000;
    border-radius: 8px;
  }

  .show {
    display: block
  }

  &__item {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    img {
      margin-right: 4px;
    }
    span {
      color: #373737;
    }
    &:hover,
    &:focus {
      background: #000000;
     span {
       color: #FFFFFF;
     }
    }
  }
}
