@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.footer {
  background: #000000;
  padding: 32px 0;
  margin-top: 32px;
  position: relative;
  @media (min-width: 992px) {
    margin-top: 48px;
  }
  &-content {
    max-width: 450px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 992px) {
      max-width: none;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .social {
      display: flex;
      &-item {
        margin-left: 4px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .rate {
    //display: inline-block;
    border: 0;
    padding: 0;
    margin-right: 5px;
    display: flex;
    flex-direction: row-reverse;
    input {
      display: none;
    }
    label {
      float: right;
      &:before {
        display: inline-block;
        //font-size: 2rem;
        padding: 0 2px;
        margin: 0;
        cursor: pointer;
        color: #DFDFDF;
        font-family: FontAwesome;
        content: "\f005 "; /* full star */
      }
    }

    .half {
      &:before {
        content: "\f089 "; /* half star no outline */
        position: absolute;
        padding-right: 0;
      }
    }

    input:checked ~ label,
    label:hover,
    label:hover ~ label {
      &:before {
        color: #FFD600
      }
    }

    input:checked + label:hover,
    input:checked ~ label:hover,
    input:checked ~ label:hover ~ label,
    label:hover ~ input:checked ~ label  {
      &:before {
        color: #FFD600
      }
    }
  }

  .rate-nohover {
    label:hover,label:hover ~ label {
      &:before {
        color: #DFDFDF;
      }
    }
  }

  .ratings {
    background: #5A5A5A;
    border-radius: 4px;
    padding: 16px 24px;

    @media (min-width: 992px) {
      flex-shrink: 0;
      max-width: 290px;
      width: 100%;
    }

    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        margin-bottom: 8px;
      }
      .name {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-transform: capitalize;
        color: #FFFFFF;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #DFDFDF;
        text-align: center;
        margin-top: 8px;
      }
      .general-rating {
        display: flex;
        align-items: center;
        span {
          color: #FFFFFF;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          text-transform: capitalize;
        }
      }
    }
    &-centre {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      max-width: 310px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      margin-top: 16px;
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 12px;
        &:first-child {
          margin-top: 0;
        }
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
      }
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        color: #FFFFFF;
      }
    }
    &-link {
      width: 100%;
      margin-top: 16px;
      button {
        padding: 16px;
        display: block;
        text-align: center;
        width: 100%;
        background: #087DD2;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        color: #FFFFFF;
        border: none;
        outline: none;
        &:hover {
          cursor: pointer;
          background: #095185;
        }
        &:disabled {
          background: #9F9F9F;
          cursor: not-allowed;
        }
      }
    }
  }

  &-contents {
    margin-top: 24px;
    @media (min-width: 992px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 48px;
    }
    .links {
      @media (min-width: 992px) {
        max-width: 910px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 24px;
      }
      &-block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 24px;
        @media (min-width: 992px) {
          width: 100%;
        }
      }
      &-item {
        width: calc(50% - 5px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          color: #FFFFFF;
          margin-bottom: 10px;
          text-transform: capitalize;
          @media (min-width: 992px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
        a {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #9F9F9F;
          margin-top: 8px;
          @media (min-width: 390px) {
            font-size: 14px;
          }
          @media (min-width: 992px) {
            font-size: 16px;
            line-height: 140%;
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
      &-link {

      }
    }
  }

  &-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid #343232;
    margin-top: 16px;
    &-block {
      display: flex;
      align-items: center;
      margin-top: 16px;
      &:first-child {
        margin-top: 0;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
      }
      img {
        margin-left: 16px;
      }
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #9C9C9C;
      }
    }
    &-age {
      padding: 8px;
      text-align: center;
      background: #FF372A;
      border-radius: 2px;
      margin-right: 16px;
      @media (min-width: 768px) {
        margin-right: 24px;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
      }
    }
  }

  .rating-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4.5px);
    padding: 56px 16px 16px 16px;
    display: none;
    align-items: flex-start;
    justify-content: center;
    @media (min-width: 992px) {
      position: absolute;
    }
    &.active {
      display: flex;
    }
    &-content {
      max-width: 450px;
      width: 100%;
    }
    .ratings {
      position: relative;
      max-width: none;
    }
    .done {
      background: #5a5a5a;
      border-radius: 4px;
      padding: 16px 24px;
      position: relative;
      display: none;
    }
    .popup-close {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
