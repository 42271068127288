.header {
  padding: 0px 20px;
  overflow: hidden;
  @media (min-width: 992px) {
    //padding: 24px 0px;
    overflow: visible;
  }
  &.active {
    height: calc(100vh);
  }

  &-desktop {
    display: none;
    justify-content: space-between;
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
    }
    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-left: 16px;
      position: relative;
      z-index: 100;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 54px;
        text-transform: capitalize;
        color: #000000;
      }
      svg {
        margin-left: 8px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        cursor: pointer;
        p {
          text-decoration: underline;
        }
        .first-menu {
          display: block;
        }
      }
    }
    .first-menu {
      position: absolute;
      top: 44px;
      //padding: 16px;
      left: 0;
      background: #FFFFFF;
      box-shadow: -4px 1px 30px rgba(79, 79, 79, 0.09);
      border-radius: 8px;
      width: 260px;
      display: none;
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid #DFDFDF;
        //margin-top: 12px;
        flex-direction: column;
        &:hover {
          text-decoration: underline;
          background: #eee;
          a {
            text-decoration-color: #000000;
          }
        }
        &:last-child {
          border: none;
          //padding-bottom: 0;
        }
        &:hover {
          .second-menu-content {
            display: flex;
          }
        }
        &:first-child {
          margin-top: 0;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-transform: capitalize;
          color: #000000;
          text-decoration: none;
        }
        &__item {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
    .second-menu {
      padding: 0;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-content {
        display: none;
        position: absolute;
        //margin-left: 24px;
        //right: -130%;
        //padding: 16px;
        top: -30px;
        right: -280px;
        /* transform: translateX(-50%); */
        background: #FFFFFF;
        box-shadow: -4px 1px 30px rgb(79 79 79 / 9%);
        border-radius: 8px;
        width: 260px;
        flex-direction: column;
        &:after {
          content: "";
          height: 100%;
          width: 25px;
          background: #000;
          position: absolute;
          top: 0;
          left: -20px;
          opacity: 0;
        }
        &:hover {
          display: flex;
        }
      }
      &-item {
        //padding-bottom: 12px;
        padding: 16px;
        border-bottom: 1px solid #DFDFDF;
        //margin-top: 12px;
        &:hover {
          background: #eee;
          text-decoration: underline;
        }
        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-transform: capitalize;
          color: #000000;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &-mobile {
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      display: none;
    }
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
    }
    &__burger {
      position: relative;
      ul{
        width: 24px;
        height: 16px;
        cursor: pointer;
        padding: 0;
        li {
          list-style: none;
          position: absolute;
          left: 5%;
          width: 100%;
          height: 2px;
          transition: .3s;
          background: #000000;
          transform: translateY(-50%) rotate(0);
          opacity: 1;
          &:nth-child(1){
            top: 20%;
          }
          &:nth-child(2){
            top: 50%;
          }
          &:nth-child(3){
            top: 80%;
          }
        }
        &.active {
          li {
            &:nth-child(1){
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
            }
            &:nth-child(2){
              opacity: 0;
            }
            &:nth-child(3){
              top: 50%;
              transform: translateY(-50%) rotate(-45deg);
            }
          }
        }
      }
    }
    &__accordion {
      display: flex;
      flex-direction: column;
      height: calc(100% - 90px);
      overflow: auto;
    }
    &__content {
      height: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      top: -100vh;
      transition: all 0.3s;
      opacity: 0;
      padding: 0 16px;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      .accordion-item {
        border-bottom: 1px solid #DFDFDF;
        border-top: 1px solid #DFDFDF;
        cursor: pointer;
        padding: 16px 0;
        &:first-child {
          border-top: none;
        }
        &:last-child {
          border-bottom: none;
        }
      }
      .accordion {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #000000;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        &:after {
          content: url("../../img/arrow.svg"); /* Unicode character for "plus" sign (+) */
          font-size: 13px;
          color: #777;
          float: right;
          transition: all 0.3s;
        }
        &.active {
          &:after {
            transform: rotate(180deg);
            transition: all 0.3s;
          }
        }
      }
      .panel {
        padding: 0 0px;
        background-color: white;
        overflow: hidden;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #5A5A5A;
          padding: 4px 0;
          display: block;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      &.active {
        top: 60px;
        opacity: 1;
        transition: all 0.3s;
      }
    }
    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .language {
        background: #000000;
        border-radius: 1000px;
        display: flex;
        align-items: center;
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          color: #FFFFFF;
        }
        img {
          margin-right: 4px;
        }
        .select__list {
          bottom: 40px;
          top: auto;
        }
      }
      .social {
        display: flex;
        &-item {
          margin-left: 4px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
