.black-link {
  padding: 14px 32px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 600px) {
    justify-content: center;
    a {
      margin-left: 16px;
    }
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }
  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }
}

.cards-block {
  padding-top: 32px;
  @media (min-width: 768px) {
    padding-top: 48px;
  }
  &-content {
   max-width: 450px;
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   @media (min-width: 992px) {
     max-width: none;
   }
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    @media (min-width: 768px) {
      font-size: 36px;
      line-height: 43px;
      padding-bottom: 16px;
    }
  }
  &-item {

  }
  .card {
    padding: 16px;
    padding-bottom: 38px;
    background: #FFFFFF;
    box-shadow: -4px 1px 30px rgba(79, 79, 79, 0.09);
    border-radius: 8px;
    position: relative;
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
    @media (min-width: 992px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    @media (min-width: 1200px) {
      padding: 24px;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (min-width: 992px) {
        max-width: 295px;
        width: 100%;
      }
      &__img {
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        .top {
          position: absolute;
          left: 20px;
          top: 20px;
          transform: translate(-50%,-50%) rotate(-45deg);
          width: 80px;
          height: 22px;
          background: #FF372A;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.09em;
            color: #FFFFFF;
          }
        }
      }
      &__description {
        margin-left: 12px;
        margin-right: 12px;
        @media (min-width: 992px) {
          margin-right: 0;
        }
        p {
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
        }
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          color: #5A5A5A;
        }
        .rate {
          display: flex;
          align-items: center;
        }
      }
      &__link {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #087DD2;
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        &:hover {
          text-decoration: underline;
        }
        @media (min-width: 1200px) {
          position: unset;
        }
      }
    }

    &-bonus {
      margin-top: 16px;
      @media (min-width: 992px) {
        margin-top: 0px;
        margin-left: 16px;
      }
      @media (min-width: 1200px) {
        display: flex;
        align-items: flex-start;
      }
      &__text {
        padding: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(8, 125, 210, 0.05);
        border-radius: 8px;
        p {
          opacity: 0.7;
          text-align: center;
          color: #087DD2;
          &:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
          }
          &:last-child {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-transform: capitalize;
          }
        }
        @media (min-width: 1200px) {
          flex-shrink: 0;
        }
      }
      &__code {
        margin-top: 16px;
        @media (min-width: 1200px) {
          margin-top: 0px;
          margin-left: 16px;
        }
        .bonus-code {
          display: flex;
          align-items: center;
          border: 1px dashed rgba(8, 125, 210, 0.2);
          border-radius: 32px;
          padding: 14px 26px;
          position: relative;
          justify-content: center;
          padding-right: 70px;
          &__left {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #087DD2;
          }
          &__right {
            width: 68px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #087DD2;
            position: absolute;
            right: 0;
            border-radius: 0px 32px 32px 0px;
            &:hover {
              cursor: pointer;
              background: #095185;
            }
          }
        }
      }
      &__description {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #365266;
        margin-top: 8px;
      }
    }

    &-register {
      margin-top: 24px;
      @media (min-width: 992px) {
        margin-top: 0px;
        max-width: 285px;
        width: 100%;
        margin-left: 16px;
      }
      &__link {
        display: block;
        width: 100%;
        background: #087DD2;
        border-radius: 8px;
        padding: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        color: #FFFFFF;
        text-align: center;
        &:hover {
          cursor: pointer;
          background: #095185;
        }
      }
      &__block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 16px;
        margin-top: 16px;
        border-top: 0.7px solid #DFDFDF;
        @media (min-width: 992px) {
          padding-top: 0;
          margin-top: 12px;
          border-top: none;
        }
        .__item {
          display: flex;
          align-items: flex-start;
          width: calc(50% - 4px);
          svg {
            flex-shrink: 0;
          }
          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #5A5A5A;
          }
        }
      }
    }

    &-offer {
      margin-top: 24px;
      @media (min-width: 992px) {
        margin-top: 0px;
        max-width: 285px;
        width: 100%;
        margin-left: 16px;
      }
      &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        margin-top: 16px;
        border-top: 0.7px solid #DFDFDF;
        @media (min-width: 992px) {
          padding-top: 0;
          margin-top: 12px;
          border-top: none;
        }
        .__item {
          display: flex;
          align-items: flex-start;
          //width: calc(50% - 4px);
          p {
            font-weight: 300;
            font-size: 10px;
            line-height: 12px;
            color: #5A5A5A;
            text-align: center;
          }
        }
      }
      &__link {
        display: block;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #087DD2;
        margin-top: 8px;
      }
    }

  }
}

.text-block {
  margin-top: 32px;
  @media (min-width: 992px) {
    margin-top: 48px;
  }
  .container {
    @media (min-width: 992px) {
      max-width: 1040px;;
    }
  }
  &-title {
    h3 {
      margin-bottom: 0!important;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rate {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      img {
        margin-right: 8px;
      }
      span {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        text-transform: capitalize;

      }
    }
  }
  .our-opinion {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    @media (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
      margin-top: 24px;
      margin-bottom: 16px;
    }
    &-content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
        align-items: flex-start;
      }
    }
    &-left {
      max-width: 320px;
      padding: 16px 0;
      @media (min-width: 992px) {
        width: 100%;
        padding: 0;
      }
    }
    &-right {
      padding: 16px 0;
      margin-top: 16px;
      border-top: 0.7px solid #DFDFDF;
      @media (min-width: 992px) {
        margin-top: 0;
        border-top: none;
        padding: 0;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #000000;
        margin-left: 8px;
        padding: 0;
      }
    }
    .pros, .cons {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-transform: capitalize;
        color: #00B512;
      }
    }
    .cons {
      p {
        color: #FF0000;
      }
    }
    a {
      margin-top: 32px;
      margin-bottom: 32px;
      @media (min-width: 992px) {
        margin-top: 0px;
        margin-bottom: 0px;
        flex-shrink: 0;
      }
    }
  }

  .our-bonus {
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    &-left {

    }
    &-right {
      width: 100%;
      @media (min-width: 992px) {
        width: fit-content;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      p {
        font-weight: 300;
      }
      b {
        font-weight: 500;
      }
      p,b {
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        color: #000000;
      }
      img {
        margin-right: 8px;
      }
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 32px;

    @media (min-width: 992px) {
      align-items: flex-start;
    }
    tbody {
      border: 1px solid #DFDFDF;
      border-bottom: none;
    }
    tr {
      //max-width: 500px;
      //width:  500px;
      &:nth-child(odd) {
        td {
          background: #FAFDFF;
        }
      }
      td {
        max-width: 250px;
        padding: 20px 16px;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        text-align: left;
        border-bottom: 1px solid #DFDFDF;
        @media (min-width: 600px) {
          width: 250px;
        }
        &:first-child {
          font-weight: 400;
        }

        &:last-child {
          border-left: 1px solid #DFDFDF;
          font-weight: 600;
        }
      }
    }
    //&-item {
    //  max-width: 490px;
    //  width: 100%;
    //  display: flex;
    //  align-items: center;
    //  border: 1px solid #DFDFDF;
    //}
    //&-left {
    //  width: 50%;
    //  padding: 16px 19px;
    //  p {
    //    font-weight: 400;
    //    font-size: 14px;
    //    line-height: 17px;
    //    color: #000000;
    //    text-align: left;
    //    padding: 0;
    //  }
    //}
    //&-right {
    //  width: calc(50% - 1px);
    //  border-left: 1px solid #DFDFDF;
    //  padding: 16px 19px;
    //  p {
    //    font-weight: 600;
    //    font-size: 14px;
    //    line-height: 17px;
    //    color: #000000;
    //    text-align: left;
    //    padding: 0;
    //  }
    //}
  }

  .statistics {
    margin: 16px 0;
    border-top: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    @media (min-width: 992px) {
      border-right: 0px solid #DFDFDF;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
    }
    &-block {
      display: flex;
      flex-direction: column;
      @media (min-width: 992px) {
        width: 50%;
      }
      //&:last-child {
      //  .statistics-item {
      //    @media (min-width: 992px) {
      //      width: calc(50% + 1px);
      //    }
      //  }
      //}
      @media (min-width: 992px) {
       flex-direction: row;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-radius: 4px 4px 0px 0px;
      padding: 19px 16px;
      border-bottom: 1px solid #DFDFDF;
      @media (min-width: 992px) {
        border-right: 1px solid #DFDFDF;
      }

      @media (min-width: 992px) {
        //width: 50%;
      }
      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        max-width: 90px;
        padding: 0;
        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 17px;
          &:first-child {
            min-width: 110px;
          }
        }
      }
      .line {
        background: #DFDFDF;
        border-radius: 1000px;
        height: 3px;
        width: 100%;
        position: relative;
        margin: 0 12px;
        @media (min-width: 992px) {
          margin: 0 24px;
        }
        &:before {
          content: "";
          width: var(--width);
          height: 100%;
          background: #00B512;
          position: absolute;
          left: 0;
          z-index: 10;
        }
      }
      .count {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
        color: #000000;
        margin-left: 12px;
        @media (min-width: 992px) {
          margin-left: 0px;
        }
      }
    }
  }

  .quote {
    background: rgba(8, 125, 210, 0.05);
    border-radius: 4px;
    padding: 16px;
    margin: 8px 0;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #9F9F9F;
    }
  }

  ol {
    counter-reset: my-awesome-counter;
    list-style: none;

    li {
      counter-increment: my-awesome-counter;
      position: relative;
      margin-top: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
      &:first-child {
        margin-top: 0;
      }
      &:before {
        content: counter(my-awesome-counter);
        position: absolute;
        --size: 32px;
        left: calc(-1 * var(--size) - 10px);
        width: var(--size);
        height: var(--size);
        top: 50%;
        border-radius: 50%;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        background: #087DD2;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
      }
    }
  }

  ul {
    li {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-transform: capitalize;
      color: #000000;
    }
  }
  img {
    margin: 8px 0;
  }
  .icon-text {
    margin: 8px 0;
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
    }
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-transform: capitalize;
      color: #000000;
    }
  }
  &-content {
    max-width: 450px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 992px) {
      max-width: none;
    }
  }
  &-item {
    margin-top: 32px;
    &:first-child {
      margin-top: 0;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #5A5A5A;
      padding: 4px 0;
      a {
        display: inline;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #5A5A5A;
        background: none;
        text-decoration: underline;
        text-transform: inherit;
        padding: 0;
        &:hover {
          background: transparent;
          color: #087DD2;
        }
      }
    }
    a {
      background: #087DD2;
      border-radius: 8px;
      padding: 16px 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      text-align: center;
      color: #FFFFFF;
      display: block;
      margin-top: 6px;
      margin-bottom: 6px;
      width: 100%;
      &:hover {
        cursor: pointer;
        background: #095185;
      }
      @media (min-width: 390px) {
        padding: 16px 32px;
      }
      @media (min-width: 768px) {
        width: fit-content;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      text-transform: capitalize;
      margin-bottom: 16px;
    }
  }
  .accordion-block {
    .accordion-item {
      background: #FFFFFF;
      box-shadow: -4px 1px 20px rgba(79, 79, 79, 0.09);
      border-radius: 4px;
      padding: 16px;
      margin-top: 12px;
      &:hover {
        cursor: pointer;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    .accordion {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      &:after {
        content: url("../../img/arrow.svg"); /* Unicode character for "plus" sign (+) */
        font-size: 13px;
        color: #777;
        float: right;
        transition: all 0.3s;
      }
      &.active {
        &:after {
          transform: rotate(180deg);
          transition: all 0.3s;
        }
      }
    }
    .panel {
      padding: 0 0px;
      background-color: white;
      overflow: hidden;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #5A5A5A;
        padding: 4px 0;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

}
